import { FC, PropsWithChildren } from 'react';
import { useRegisterSW } from 'virtual:pwa-register/react';
import { ServiceWorkerContext } from '../context/service-worker-context';
import { pwaInfo } from 'virtual:pwa-info';

// eslint-disable-next-line no-console
console.log(pwaInfo);

const intervalMS = 900000;
const buildDate = __VITE_BUILD_DATE__;

export const ServiceWorkerProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    offlineReady: [offlineReady],
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, registration) {
      console.log(`Service Worker at: ${swUrl}, build at: ${buildDate}`);

      registration &&
        setInterval(async () => {
          if (!(!registration.installing && navigator)) return;

          if ('connection' in navigator && !navigator.onLine) return;

          const resp = await fetch(swUrl, {
            cache: 'no-store',
            headers: {
              cache: 'no-store',
              'cache-control': 'no-cache',
            },
          });

          if (resp?.status === 200) await registration.update();
        }, intervalMS);
    },
    onRegisterError(error) {
      console.log('SW registration error', error);
    },
    onNeedRefresh() {
      console.log('Update available');
    },
  });

  return (
    <ServiceWorkerContext.Provider value={{ offlineReady, needRefresh, buildDate: buildDate, updateServiceWorker }}>{children}</ServiceWorkerContext.Provider>
  );
};
